import * as bootstrap from "bootstrap";
import jquery from "jquery";
import validate from "jquery-validation";
import DataTable from "datatables.net-bs5";

import Tags from "bootstrap5-tags";
import datetimepicker from "jquery-datetimepicker";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.jQuery = jquery;
window.$ = jquery;
window.Stimulus = Application.start();

const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

require("./admin_post/adminPostsDataTable");

$(".datetimepicker").datetimepicker({
  showTimezone: true,
});

$.fn.dataTable.ext.errMode = "none";

let selectedCollapse = sessionStorage.getItem("selectedCollapse");
let current = sessionStorage.getItem("current");
let name = sessionStorage.getItem("current_list");

if (name != null) {
  $(name).addClass("active");
}

if (selectedCollapse != null) {
  $(".main-navbar .collapse").removeClass("show");
  $(".main-navbar .btn-click").addClass("collapsed");
  $(selectedCollapse).addClass("show");

  let value = $(`#${current} a`);
  $(value[0]).removeClass("collapsed");
}

$(".main-navbar .btn-click").on("click", function () {
  let target = $(this).attr("data-bs-target");
  sessionStorage.setItem("selectedCollapse", target);
  let id = $(this).closest("li").attr("id");
  sessionStorage.setItem("current", id);
});
window.addEventListener("DOMContentLoaded", () => {
  Tags.init();
  // const spanElements = document.querySelectorAll('span.text-truncate');
  // spanElements.forEach((spanElement) => {
  //   spanElement.classList.remove('text-truncate');
  //   });

  // const btnElements = document.querySelectorAll('button.btn-close-white');

  // btnElements.forEach((btnElement) => {
  //   btnElement.classList.remove('btn-close-white');
  // });
});

// Get the current state of the submenu from local storage
var submenuState = localStorage.getItem("submenu1");

// If the submenu state is "show", display the submenu
if (submenuState === "show") {
  $("#submenu1").addClass("show");
}

// When the submenu is shown or hidden, update the local storage
$("#submenu1").on("shown.bs.collapse hidden.bs.collapse", function () {
  localStorage.setItem("submenu1", $(this).hasClass("show") ? "show" : "hide");
});

// Get the current state of the submenu from local storage
var submenuState = localStorage.getItem("submenu2");

// If the submenu state is "show", display the submenu
if (submenuState === "show") {
  $("#submenu2").addClass("show");
}

// When the submenu is shown or hidden, update the local storage
$("#submenu2").on("shown.bs.collapse hidden.bs.collapse", function () {
  localStorage.setItem("submenu2", $(this).hasClass("show") ? "show" : "hide");
});

// Get the current state of the submenu from local storage
var submenuState = localStorage.getItem("submenu3");

// If the submenu state is "show", display the submenu
if (submenuState === "show") {
  $("#submenu3").addClass("show");
}

// When the submenu is shown or hidden, update the local storage
$("#submenu3").on("shown.bs.collapse hidden.bs.collapse", function () {
  localStorage.setItem("submenu3", $(this).hasClass("show") ? "show" : "hide");
});

// Get the current state of the submenu from local storage
var submenuState = localStorage.getItem("submenu4");

// If the submenu state is "show", display the submenu
if (submenuState === "show") {
  $("#submenu4").addClass("show");
}

// When the submenu is shown or hidden, update the local storage
$("#submenu4").on("shown.bs.collapse hidden.bs.collapse", function () {
  localStorage.setItem("submenu4", $(this).hasClass("show") ? "show" : "hide");
});

// Get the current state of the submenu from local storage
var submenuState = localStorage.getItem("submenu5");

// If the submenu state is "show", display the submenu
if (submenuState === "show") {
  $("#submenu5").addClass("show");
}

// When the submenu is shown or hidden, update the local storage
$("#submenu5").on("shown.bs.collapse hidden.bs.collapse", function () {
  localStorage.setItem("submenu5", $(this).hasClass("show") ? "show" : "hide");
});

$(document).ready(function () {
  $("form").validate();
  setTimeout(function () {
    $(".flash_notice").slideUp();
  }, 1100);
});

$(document).ready(function () {
  $("table").addClass("table-striped");

  $(document).on("click", ".confirm-delete", function (event) {
    var proceed = confirm("Are you sure you want to Delete?");
    if (!proceed) {
      event.preventDefault();
    }
  });

  $("#serving-datatable").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    ajax: $(location).attr("href"),
    searchDelay: 1000,
    language: {
      searchPlaceholder: "Search",
    },
  });
});
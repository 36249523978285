window.addEventListener('DOMContentLoaded', () => {

  $('#posts_table').DataTable({

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "order": [[0, "desc"]],
  })
});

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["name"];
  connect()
  {

    JSON.parse($("#product_discount_available").val()) ? this.showProductUrl : this.hideProductUrl();

    const context = this
    $(document).on('change', '#discount-available', function(event) {
      console.log("hell clicked")
      context.showProductUrl();
      
      if ($(this).is(":checked"))
      {
        context.showProductUrl();
      }
      else
      {
        context.hideProductUrl();
      }
    }); 
  }

  showProductUrl()
  {
    $("#product-url").show();
  }

  hideProductUrl()
  {
    $("#product-url").hide();
  }

}
